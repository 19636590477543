var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{class:['photo-preview', _vm.classes],attrs:{"to":{ path: '/photo/' + _vm.collection.id }}},[_c('div',{staticClass:"grid-container"},[_c('div',{class:[
        'grid-type',
        {
          'grid-type--big-on-left': _vm.collectionType === 'left' && !_vm.isVertical
        },
        {
          'grid-type--big-on-right': _vm.collectionType === 'right' && !_vm.isVertical
        },
        { 'grid-type--oneline': _vm.isVertical }
      ]},_vm._l((_vm.photos),function(image,index){return _c('figure',{key:index,staticClass:"grid__item"},[_c('img',{staticClass:"grid__img",attrs:{"src":image.src,"loading":"lazy"}})])}),0)]),(_vm.collection.title && !_vm.isHideTitle)?_c('h2',{staticClass:"photo-preview__title"},[_vm._v(" "+_vm._s(_vm.collection.title)+" ")]):_vm._e(),_vm._t("default"),_vm._t("category")],2)}
var staticRenderFns = []

export { render, staticRenderFns }